import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { openWhatsapp } from "../../util/openWhatsapp";
import { ModalSuccessStyles } from "./ModalSuccessStyles";

interface ModalPurchaseModuleProps {
  title: string;
  content: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalPurchaseModule = (props: ModalPurchaseModuleProps) => {
  const { open, setOpen, title, content } = props;
  const classes = ModalSuccessStyles();

  const confirmHandler = () => {
    openWhatsapp("31992330282", "Quero saber mais sobre o UPGRADE");
    setOpen(false);
  };

  const closeHandler = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={closeHandler}>
        <DialogTitle className={classes.successModalTitle}>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.successModalContent}>
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmHandler} color="primary">
            Chamar WhatsApp
          </Button>
          <Button onClick={closeHandler} color="default">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
