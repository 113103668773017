import React, { useState, useContext } from "react";
import Button from "@material-ui/core/Button";

import { useHttpClient } from "../../hooks/httpHook";
import { ModalError } from "./ModalError";
import { LoadingSpinnerOverlayRegular } from "./LoadingSpinnerOverlayRegular";
import { AuthContext } from "../../context/authContext";
import { Notification } from "./NotificationsMenu";

import { NotificationsMenuStyles } from "./NotificationsMenuStyles";

interface NotificationItemProps {
  notification: Notification;
  setDeletions: React.Dispatch<React.SetStateAction<number>>;
}

export const NotificationItem = (props: NotificationItemProps) => {
  const { notification, setDeletions } = props;
  const { id, title, content, openUrl, type, registryDate } = notification;
  const [deleted, setDeleted] = useState(false);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const classes = NotificationsMenuStyles();

  const notificationDeleteHandler = async (id: string) => {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/notifications/user/${id}/${auth.userId}`;

    try {
      await sendRequest(apiUrl, "DELETE", null, {
        Authorization: "Bearer " + auth.token,
      });
      setDeleted(true);
      setDeletions((prevValue) => {
        return prevValue + 1;
      });
    } catch (err) {}
  };

  if (deleted) {
    return null;
  }

  return (
    <React.Fragment>
      <ModalError error={error} onClear={clearError} />
      <li className={classes.notificationItem}>
        {isLoading && <LoadingSpinnerOverlayRegular infoCard />}
        <p
          style={{
            fontSize: "0.8rem",
            fontWeight: 700,
            margin: "0 17px 8px 17px",
          }}
        >
          {title}
        </p>
        <p
          style={{
            fontSize: "0.8rem",
            margin: "0 17px 2px 17px",
          }}
        >
          {content}
        </p>
        <p
          style={{
            fontSize: "0.7rem",
            margin: "0 17px 5px 17px",
          }}
        >
          {registryDate?.replace("|", "-")}
        </p>
        <div className={classes.notificationButtons}>
          {openUrl !== "https://azume.com.br/" && (
            <Button
              style={{ marginLeft: "10px" }}
              color="primary"
              onClick={() => {
                const win = window.open(openUrl, "_blank");
                win.focus();
              }}
            >
              VISUALIZAR
            </Button>
          )}
          {type === "PRIVATE" || type === "ADMIN" ? (
            <Button
              disabled={auth.userIsAdmin && auth.status !== "OWNER"}
              style={{ color: "#999", marginLeft: "6px" }}
              onClick={() => {
                notificationDeleteHandler(id);
              }}
            >
              EXCLUIR
            </Button>
          ) : null}
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className={classes.notificationBorder}></div>
        </div>
      </li>
    </React.Fragment>
  );
};
